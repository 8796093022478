/* You can add global styles to this file, and also import other style files */
html {
  height: auto;
text-align: center;
background-color: #F8F9F9;
margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 14px !important;
  line-height: 17px;
}
body {
  font-size: 14px !important;
  height: auto;
  text-align: left;
  background-color: #F8F9F9;
  margin: 0;
  padding: 0;    
  font-family: Roboto;
  color: #000000;    
  overflow-x: hidden;
}
a {
  color: #017374;
  text-decoration: none;
}
.page-title {
  font-size: 20px;
  font-weight: 600;
}
.displayFlex {
  display: flex;
}
.modalLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.btnLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width:20px;
  height:20px;
}
.celLink {
  cursor: pointer;
  color:#268888;
  font-weight: bold;
}
.layoutPad {
  padding: 0 20px;
}
.green {
  color: #00A19B !important;
}
.red {
  color: #ff0000 !important;
}
.pageHeader {
  padding: 0 20px !important;
  background-color: #00736f;
  color: #fff;
  min-height: 80px;
}
.pointer {
  cursor: pointer;
}
.p-dialog .p-dialog-content {
  overflow: visible;
}
.p-inputtext, .p-multiselect, .p-dropdown {    
  border-radius: 5px;    
}
.p-fluid .p-calendar .p-inputtext {    
  border-radius: 5px 0px 0  5px;    
}
.mainSearch .p-component{
  width: 100%;
}
.p-datatable table {
  font-size: 13px;
}
.p-datatable table th {
  font-size: 14px;
}
.p-datatable .p-datatable-tbody > tr {
  border: 1px solid #E9F0EF;
  border-radius: 5px;
}

.conventionsTable .p-datatable .p-datatable-tbody > tr > td{
  padding: 0.1rem 1rem
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  font-size: 16px;
}
.p-datatable .p-datatable-thead > tr > th {
  background-color: transparent;
}
.p-datatable .p-datatable-thead > tr:nth-child(2) {
  background-color: #E9F0EF;
}
.greenTable .p-datatable .p-datatable-tbody > tr {
  background-color: #D3EBE8;
}
.loginForm .p-card {
  top: 20%;
  width: 20%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 5px;
}
.p-menu .p-submenu-header {
  background-color: transparent;
}
.p-text-medium {
  font-weight: 500;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font46 {
  font-size: 46px;
  line-height: 56px;
}
.no-left-padding {
  padding-left: 0px;
  button {
      margin-top: 24px;
      height: 33px;
      border-radius: 0px !important;
  }
}
.no-right-padding {
  padding-right: 0px;
  input {
      border-radius: 5px 0px 0px 5px;
      border-right-color: transparent !important;
  }
}

.disabled-control {
  pointer-events: none;
  opacity: 0.7;
}

.disabled-control button, .disabled-control i, .disabled-control span {
  background-color: lightgray !important;
  border-color: gray !important;
}

.p-multiselect-item-group {
  font-weight: bold;
  margin-left: 10px;
  font-size: 15px;
}

textarea {
  resize: none;
}
.width_1 {
  width: 1%;
}
.width_5 {
  width: 5%;
}
.width_10 {
  width: 10%;
}
.width_15 {
  width: 15%;
}
.width_20 {
  width: 20%;
}
.width_25 {
  width: 25%;
}
.width_30 {
  width: 30%;
}
.width_35 {
  width: 35%;
}
.width_40 {
  width: 40%;
}
.width_50 {
  width: 50%;
}
.width_60 {
  width: 60%;
}
.width_70 {
  width: 70%;
}
.width_80 {
  width: 80%;
}
.width_85 {
  width: 85%;
}
.width_90 {
  width: 90%;
}
.width_95 {
  width: 95%;
}
.width_100 {
  width: 100%;
}
.height100p textarea {
  height: 100px;
}
.whiteBG {
  background-color: #fff;
}
.deleteCase {
  border: 1px solid #B90000 !important;
  color: #B90000 !important;
  background-color: transparent !important;
  opacity: 0.6 !important;
}

.p-fileupload-row > div:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
}
.scroll60 {
  height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ss .ul-horizontal {
  display: flex !important;
  margin-bottom: 0px !important;
  width: 100%;
  display: table !important;
}
.ss .ul-horizontal li {
  display: table-cell;
}
.ss .h-menuItem {
  padding: 15px 25px !important;
  width: 100% !important;
  font-size: 18px !important;
  cursor: pointer !important;
  text-align: center !important;
  margin-right: 0 !important;
  color: #000 !important;
  background-color: #EBEBEB !important;
  border: 1px solid #BFBFBF !important;
  height: auto !important;
  border-radius: 0 !important;
}
.ss .h-menuItem:hover {    
  color: #00736f !important;

}
.ss .h-menuItem.selected {
  background-color:#fff !important;
  color: #000 !important;
  border-bottom-color: #fff !important;
}
.p-button.p-button-rounded:disabled {
  opacity: 0.7;
  background-color: #D9D9D9!important;
  border: none !important;
  cursor: not-allowed;
}
.p-button.p-button-icon-only:disabled {
  opacity: 0.5;
  cursor: not-allowed;    
}
.collapsedHeader .compInfoRibbon {
  display: none;
}
.collapsedHeader .header {
  min-height: 30px !important;
}
.collapsedHeader .caseInfoRibbon {
  min-height: 30px !important;
  height: 105px;
}
.gssPage {
  height: 70vh;
}
.fixedRibbons {
  position: relative;
  z-index: 998;
  transition: all 1s linear
}
.collapsedHeader .fixedRibbons  {
  position: fixed;
  transition: all 1s linear;
}
.collapsedHeader {
  height: 280px;
}
ul li .bubble {
  cursor: inherit !important;
}
.NgxEditor {
  min-height: 200px;
}
.NgxEditor__Content--Disabled {
  opacity: 0.7;
  background-color: #ebebeb;
  user-select: auto !important;
}
.relative {
  position: relative;
}
.p-ink {
  display: none !important;
}